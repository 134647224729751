import {modalLayer, ModalLayerContentBase} from "core-application";
import {modalLayerConsumptionEmissionTemplate} from './templates/modal-layer-consumption-emission-template';
import {template} from "core-utils";

export default class ModalConsumptionEmissionLayerElement extends ModalLayerContentBase {

	constructor(data_ = {}) {
		super();
		this._bindContextToFunctions();
		this.data = data_;
		this.data.url = `${this._getFeatureAppBaseUrl()}/fh/app.js`;
		this.data.id = data_.id;
		this.data.config = {vehicleId: data_.id, eecImageUrl: `${this._getFeatureAppBaseUrl()}/static`};
		if (SETUPS.get('nemo.locale.country') === 'CH' || SETUPS.get('nemo.locale.country') === 'FR') {
			this.data.config.useEfficiencyImage = true;
		}
	}

	/**
	 * static getter function for default CSS Selectors
	 * @static
	 * @returns {object <{closeButton: string, confirmButton: string}>} defaults defaults strings
	 */
	static get defaults() {
		return {
			closeButton: '.modal-layer--close-button'
		};
	}

	/**
	 * type - getter for type
	 * @returns {string} type string
	 */
	static get type() {
		return 'ModalConsumptionEmissionLayerElement';
	}

	/**
	 * getContent
	 * @param {object} data_ data
	 * @returns {Promise<ModalConsumptionEmissionLayerElement>} modal consumption layer element
	 */
	static async getContent(data_) {
		return new ModalConsumptionEmissionLayerElement(data_);
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this._render();
		this.addEvents();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this.removeEvents();
	}

	/**
	 * _bindContextToFunctions - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._closeLayer = this._closeLayer.bind(this);
		this._render = this._render.bind(this);
	}

	/**
	 * addEvents
	 * @returns {void} returns nothing
	 */
	addEvents() {
		this.closeButton.addEventListener('click', this._closeLayer);
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	removeEvents() {
		this.closeButton.removeEventListener('click', this._closeLayer);
	}

	/**
	 * getFeatureBaseAppUrl
	 * @returns {string} returns base url of consumption FA
	 */
	_getFeatureAppBaseUrl() {
		const alphaVersion = SETUPS.get('stockcar.vtp.fa.use.alpha.version') ? '-alpha' : '',
			version = SETUPS.get('stockcar.consumption.emission.fa.version') || '2.5.0',
			part = (version !== '' ? `${version}${alphaVersion}` : '');
		return `https://fa-vtp-consumption.cdn.prod.arcade.apps.one.audi/${part}`;
	}

	/**
	 * render
	 * @returns {void} returns nothing
	 */
	_render() {
		const data = {...this.data};
		data.config = JSON.stringify(data.config);
		this.innerHTML = template.render(modalLayerConsumptionEmissionTemplate, data);
		this.closeButton = this.querySelector(ModalConsumptionEmissionLayerElement.defaults.closeButton);
	}

	/**
	 * _closeLayer - closes modal layer
	 * @returns {void} void
	 * @private
	 */
	_closeLayer() {
		this._triggerClose();
	}
}

modalLayer.registerLayerType(ModalConsumptionEmissionLayerElement);

if (window.customElements.get('modal-consumption-emission-layer-element') === undefined) {
	window.customElements.define('modal-consumption-emission-layer-element', ModalConsumptionEmissionLayerElement);
}
