import {modalLayerFootnoteEngineTemplate} from 'core-application';

const exports = `
	<div class="modal-layer__inner">
		<div class="modal-layer--close">
			<div class="modal-layer--close-button">
				<svg class="nm-icon-cancel">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel-newci-small.svg#audi-icon-cancel-newci-small"}}></use>
				</svg>
			</div>
		</div>
		<div class="modal-layer__inner-content consumption-emission-layer__inner-content" data-layer-options='{"addCssClass": "sc-modal-layer-wrapper"}'>
			{{? it.id && it.url}}
				<feature-app id={{="consumption-emission-app-"+it.id}}
				 src={{=it.url}}
				 config='{{=it.config}}'></feature-app>
	<sc-fa-cache-killer/>
			{{?}}
			${modalLayerFootnoteEngineTemplate}
		</div>
	</div>
`;

export {exports as modalLayerConsumptionEmissionTemplate};
