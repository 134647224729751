import {modalLayer, ModalLayerContentBase} from 'core-application';
import {modalLayerNotificationTemplate} from './templates/modal-layer-notification-template';
import {template} from "core-utils";

export default class ModalNotificationLayerElement extends ModalLayerContentBase {

	constructor(data_ = {}) {
		super();
		this._bindContextToFunctions();
		this.data = data_;
		this.data.type = data_.type;
		this.data.headline = data_.headline;
		this.data.message = data_.message;
	}

	/**
	 * static getter function for default CSS Selectors
	 * @static
	 * @returns {object <{closeButton: string, confirmButton: string}>} defaults
	 */
	static get defaults (){
		return {
			closeButton: '.modal-layer--close-button',
			confirmButton: '.modal-layer-confirm-button'
		};
	}

	static get type() {
		return 'ModalNotificationLayerElement';
	}

	static async getContent(data_) {
		return new ModalNotificationLayerElement(data_);
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this._render();
		this.addEvents();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this.removeEvents();
	}

	/**
	 * _bindContextToFunctions - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._closeLayer = this._closeLayer.bind(this);
		this._render = this._render.bind(this);
	}

	/**
	 * addEvents
	 * @returns {void} returns nothing
	 */
	addEvents() {
		this.closeButton.addEventListener('click', this._closeLayer);
		this.confirmButton.addEventListener('click', this._closeLayer);
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	removeEvents() {
		this.closeButton.removeEventListener('click', this._closeLayer);
		this.confirmButton.removeEventListener('click', this._closeLayer);
	}

	/**
	 * _generate data
	 * @param {object} data - rendering data for error handling
	 * @returns {object} data - prepared data
	 * @private
	 */
	_generateData(data) {
		if (data && data.type === 'error' && data.errorType) {
			data.headline = window.i18n['sc.error.headline'];
			data.message = window.i18n['sc.error.' + data.errorType.replace(/ /g, ".")];
		}
		return data;
	}

	/**
	 * render
	 * @returns {void} returns nothing
	 */
	_render () {
		const data = this._generateData(this.data);
		this.innerHTML = template.render(modalLayerNotificationTemplate, data);
		this.closeButton = this.querySelector(ModalNotificationLayerElement.defaults.closeButton);
		this.confirmButton = this.querySelector(ModalNotificationLayerElement.defaults.confirmButton);
	}

	_closeLayer() {
		this._triggerClose();
	}
}

modalLayer.registerLayerType(ModalNotificationLayerElement);

if (window.customElements.get('modal-notification-layer-element') === undefined) {
	window.customElements.define('modal-notification-layer-element', ModalNotificationLayerElement);
}
