import {modalLayer, ModalLayerContentBase} from "core-application";
import {modalLayerDealerImprintTemplate} from './templates/modal-layer-dealer-imprint-template';
import {template} from "core-utils";

export default class ModalDealerImprintLayerElement extends ModalLayerContentBase {

	constructor(data_ = {}) {
		super();
		this._bindContextToFunctions();
		this.data = data_;
		this.data.imprint = data_.imprint;
	}

	/**
	 * static getter function for default CSS Selectors
	 * @static
	 * @returns {object <{closeButton: string, confirmButton: string}>} defaults
	 */
	static get defaults (){
		return {
			modalLayerConsumptionEmissionClass: 'modal-consumption-emission-layer-element',
			closeButton: '.modal-layer--close-button'
		};
	}

	static get type() {
		return 'ModalDealerImprintLayerElement';
	}

	static async getContent(data_) {
		return new ModalDealerImprintLayerElement(data_);
	}

	/**
	 * connectedCallback
	 * @returns {void} returns nothing
	 */
	connectedCallback() {
		this._render();
		this.addEvents();
	}

	/**
	 * disconnectedCallback
	 * @returns {void} returns nothing
	 */
	disconnectedCallback() {
		this.removeEvents();
	}

	/**
	 * _bindContextToFunctions - bind 'this' context to necessary functions
	 * @returns {void} returns nothing
	 */
	_bindContextToFunctions() {
		this._closeLayer = this._closeLayer.bind(this);
	}

	/**
	 * addEvents
	 * @returns {void} returns nothing
	 */
	addEvents() {
		this.closeButton.addEventListener('click', this._closeLayer);
	}

	/**
	 * removeEvents
	 * @returns {void} returns nothing
	 */
	removeEvents() {
		this.closeButton.removeEventListener('click', this._closeLayer);
	}

	/**
	 * render
	 * @returns {void} returns nothing
	 */
	_render () {
		// this.classList.add(ModalDealerImprintLayerElement.defaults.modalLayerConsumptionEmissionClass);
		this.innerHTML = template.render(modalLayerDealerImprintTemplate, this.data);
		this.closeButton = this.querySelector(ModalDealerImprintLayerElement.defaults.closeButton);
	}

	_closeLayer() {
		this._triggerClose();
	}
}

modalLayer.registerLayerType(ModalDealerImprintLayerElement);

if (window.customElements.get('modal-dealer-imprint-layer-element') === undefined) {
	window.customElements.define('modal-dealer-imprint-layer-element', ModalDealerImprintLayerElement);
}
