const exports = `
<div class="nm-layer-toolbar">
	<div class="nm-button-close">
		<svg class="nm-icon-cancel">
			<use xlink:href= {{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel.svg#nm-icon-cancel"}}></use>
		</svg>
	</div>
</div>

<div class="nm-layer-inner sc-results-eec-wrapper sc-md-details-technical-data sc-hyphenate">
	<h2 class="sc-results-eec-headline nm-el-hdl nm-el-hdl-02 nm-at-hdl-b">{{=window.i18n['sc.result.eec.headline']}}</h2>
	<div class="sc-results-eec-content sc-techdata-list">
		<dl class="sc-results-data-list-item sc-details-technical-data-eec">
			<dt class="sc-details-technical-data-label nm-el-lbl nm-el-lbl-01 nm-at-lbl-b">{{=window.i18n['sc.details.technical-data.eec.label']}}</dt>
			<dd class="sc-results-data-value nm-el-lbl nm-el-lbl-01">
				<span>{{=window.i18n['sc.details.technical-data.eec.introduction']}}</span>
				<div class="sc-results-data-eec-image-wrap">
					<span class="sc-results-data-eec-image" style="background-image: url('{{=SETUPS.get('stockcar.url.assets')}}/img/eec/eec-label-{{=it.envkv.efficiencyClass.toLowerCase()}}.png');"></span>
				</div>
				<dl class="sc-results-data-value-item">
					<dt class="sc-results-data-value-label">{{=it.envkv.consumptionTax.label}}</dt>
					<dd class="nm-el-lbl nm-el-lbl-01 nm-at-lbl-b">{{=it.envkv.consumptionTax.price.formatted}}</dd>
				</dl>
				<dl class="sc-results-data-value-item">
					<dt class="sc-results-data-value-label">{{=it.envkv.fuelCosts.label}}</dt>
					<dd class="nm-el-lbl nm-el-lbl-01 nm-at-lbl-b">{{=it.envkv.fuelCosts.price.formatted}}</dd>
				</dl>
				<span class="sc-details-technical-data-eec-date">{{=window.i18n['sc.details.technical-data.eec.date.label']}} {{=it.envkv.creationDateFormatted}}</span>
			</dd>
		</dl>
		<div class="sc-results-eec-footnotes">
			{{=it.envkvDisclaimer}}
		</div>
		</div>
	</div>
</div>
`;

export {exports as eecModalLayerTemplate};
