const exports = `
	<div class="modal-layer__inner">
		<div class="modal-layer__header">
			<div class="modal-layer--close">
				<div class="modal-layer--close-button">
					<svg class="nm-icon-cancel">
						<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel.svg#nm-icon-cancel"}}></use>
					</svg>
				</div>
			</div>
			<div class="modal-layer-headline-wrap" data-type="{{=it.type}}">
				<svg class="nm-icon-confirmation-large modal-layer-icon-confirmation">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/confirmation-large.svg#nm-icon-confirmation-large"}}></use>
				</svg>
				<svg class="nm-icon-caution-large modal-layer-icon-caution">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/caution-large.svg#nm-icon-caution-large"}}></use>
				</svg>
				<p class="modal-layer-headline audi-headline-order-3">{{=it.headline}}</p>
			</div>
		</div>
		<div class="modal-layer__inner-content">
			<div class="modal-layer-message">
				<p class="audi-copy-m">{{=it.message}}</p>
				<button class="modal-layer-confirm-button audi-button"><span class="audi-button__text">OK</span></button>
			</div>
		</div>
	</div>
`;

export {exports as modalLayerNotificationTemplate};
