const exports = `
	<div class="modal-layer__inner">
		<div class="modal-layer--close">
			<div class="modal-layer--close-button">
				<svg class="nm-icon-cancel">
					<use xlink:href={{=SETUPS.get('coreci.url.assets')+"/icons/svg/cancel-newci-small.svg#audi-icon-cancel-newci-small"}}></use>
				</svg>
			</div>
		</div>
		<div class="modal-layer__inner-content" data-layer-options='{"addCssClass": "sc-modal-layer-wrapper"}'>
			{{? it.imprint}}
				<h2 class="sc-dealer-imprint-headline audi-headline-order-2 audi-headline--bold sc-hyphenate">{{=window.i18n['sc.details.dealer.imprint']||'sc.details.dealer.imprint'}}</h2>
				<div class="sc-dealer-imprint-content audi-copy-s">
					{{=it.imprint}}
				</div>
			{{?}}
			{{? it.isaffiliatecompany === 'true'}}
				<div class="audi-copy-m affiliate-company-disclaimer-text">
					{{=window.i18n['sc.details.affiliate.company.disclaimer.text']}}
				</div>
			{{?}}	
		</div>
	</div>
`;

export {exports as modalLayerDealerImprintTemplate};
